var Loader = require('../_util/_loader');
var stageW,stageH;
var stagePos={};

global.Root = {};

/*
* google map
*/
//Root.mapInit = require('../_util/_googlemap');


$(function($) {
  var isPC;
  var isLoading=true;
  var loadTargCnt=0;
  var loadedCnt=0;
  var loadLife;
  var loadItv;
  var breakPoint=750;
  var lastScroll=0;
  var heroTo;
  var heroCopyTmpl;
  var videos={};

  var isIndex=($("#top").length>0);

  //loading
	Loader.init(init,standby,loadEnd);

  function init(){
    loadTargCnt=1;
    loadedCnt=0;

    imagePreload();
    videoInit();

    if(isIndex){
      heroInit();
    }
    titleInit();

    if($(".instaLoader").length){
      instaLoad();
    }
  }

  function standby(){
    resizeInit();
    btnInit();
  }

  function loadEnd(){
    loadedCnt++;
    loadLife=50;
    clearInterval(loadItv);
    loadItv=setInterval(function(){
      loadLife--;
      if(loadedCnt>=loadTargCnt || loadLife<0){
        clearInterval(loadItv);
        $(".loader").fadeOut(1000,play);
      }
    },100);

  }

  //loadEnd
  function play(){
    isLoading=false;
    $("header").removeClass("hide");
    scrollCheck();

    if(isIndex){
      heroPlay();
    }else{
      if($("video.autoPlay").length){
        $("video.autoPlay").eq(0)[0].play();
      }
    }
  }

  function imagePreload(){
    //Loader.imgRegist("./img/sprite/contents.png");
  }

	function resizeInit(){
    /*
		if(isSP || isTAB){
      resizeCheck();
      orientationCheck();
      $(window).on("orientationchange",function(){
        resizeCheck();
        scrollCheck();
        orientationCheck();
      });
    }else{
    */
      resizeCheck();
      $(window).resize(function(){
        resizeCheck();
        scrollCheck();
      });
    //}

    scrollCheck();
    $(window).scroll(function(){
      scrollCheck();
    });

  }

  function resizeCheck(){
    stageW=$(window).width();//window.innerWidth || document.documentElement.clientWidth;
    stageH=$(window).height();//window.innerHeight || document.documentElement.clientHeight;
		//store.state.gameScale = (stageH - 132 - 229)/store.const.gameH;

    if(stageW>breakPoint && !isPC){
      isPC = true;
    }else if(stageW<=breakPoint && isPC){
      isPC = false;
    }

    $(".full").height(stageH);

    $(".size-cover").each(function(){
      var d = $(this).data();

      if(!d.width){
        $(this).data("width",$(this).width());
        $(this).data("height",$(this).height());
      }
      var $area = $(this).parent();
      var areaW = $area.width();
      var areaH = $area.height();
      var srcW = d.width;
      var srcH = d.height;
      var sw = areaW/srcW;
      var sh = areaH/srcH;

      var s = sw>sh ? sw:sh;

      $(this).css({width:(srcW * s)+2, height:(srcH * s)+2});

    });
    $(".size-contain").each(function(){
      var d = $(this).data();

      if(!d.width){
        $(this).data("width",$(this).width());
        $(this).data("height",$(this).height());
      }
      var $area = $(this).parent();
      var areaW = $area.width();
      var areaH = $area.height();
      var srcW = d.width;
      var srcH = d.height;
      var sw = areaW/srcW;
      var sh = areaH/srcH;

      var s = sw<sh ? sw:sh;

      $(this).css({width:(srcW * s), height:(srcH * s)});

    });

  }
  function scrollCheck(){
    stagePos.top=$(window).scrollTop();//(isSP)?parseInt(window.pageYOffset)/parseFloat($("html").css("zoom")):$(window).scrollTop();
    stagePos.left=$(window).scrollLeft();//(isSP)?parseInt(window.pageXOffset)/parseFloat($("html").css("zoom")):$(window).scrollLeft();
    stagePos.center=stagePos.top+stageH/2;
    stagePos.bottom=stagePos.top+stageH;
    stagePos.target=stagePos.top+stageH*0.8;

    if(!isLoading){
      if(!$("body").hasClass("navOpen") && !isPC){
        if(stagePos.top>stageH*0.9){
          $(".header").addClass("fix");
        }else{
          $(".header").removeClass("fix");
        }
      }

      $(".contents .out").each(function(){
        if($(this).offset().top<stagePos.target){
          $(this).removeClass("out");
        }
      })
    }

		resizeCheck();
  }
  function orientationCheck(){
    //store.temp.orient=(Math.abs(window.orientation) === 90);
  }
/*
	//iOS safari 画面切り替え
	window.addEventListener('blur', function() {
    //console.log('blur:addEventListener');
		//store.timerStop();
		//sound.killFocus();
	}, false);
	window.addEventListener('focus', function() {
    //console.log('focus:addEventListener');
		//store.timerRestart();
		//sound.getFocus();
	}, false);
*/


  function btnInit(){
    $(".header_navBtn").click(function(){
      if($("body").hasClass("navOpen")){
        navClose();
      }else{
        navOpen();
      }
      return false;
    });
    $(".overlay_cover").click(function(){
      navClose();
      return false;
    });

    $(".video_playBtn").each(function(){
      var trg = $(this).data().target;
      $(this).click(function(){
        if($(videos[trg]).parent().hasClass("play")){
          videos[trg].pause();
        }else{
          videos[trg].play();
        }
        return false;
      })
    })

    $(".viewer").click(function(){
      $(this).fadeOut(400);
      return false;
    })

    $(".gallery_images>li a").each(function(i){
      var num = (i<9)?"0"+(i+1):i+1;
      var url = "/img/gallery/image-"+num+".jpg";
      $(this).click(function(){
        largeImageOpen(url,1600,1067);
        return false;
      })
    });

  }

	function scrollTo(y,doAnime){
		if(doAnime){
			$('html,body').stop(true).animate({scrollTop:y},600,'easeOutCubic',function(){scrollCheck();});
			$('html').animate({top:0},600,'linear');
		}else{
			$('html,body').stop(true).animate({scrollTop:y},0,'linear',function(){scrollCheck();});
		}
	}

  /*
  * video
  */
  function videoInit(){
    $("video").each(function(){
      var id = $(this).attr('id');
      var v = document.getElementById(id);
      videos[id]=v;

      v.addEventListener("loadeddata", function(){
        console.log("loadeddata");
        loadedCnt++;
    	}, false);
      v.addEventListener("play", function(){
        $(this).parent().addClass("play").removeClass("pause");
    	}, false);
      v.addEventListener("pause", function(){
        $(this).parent().removeClass("play").addClass("pause");
    	}, false);
      v.addEventListener("ended", function(){
        this.currentTime = 0;
        $(this).parent().removeClass("play pause");
    	}, false);

      loadTargCnt++;
    });
  }

  /*
  * hero
  */
  function heroInit(){
    heroCopyTmpl = $(".hero_copy").html();

    $(".hero_copy span").each(function(){
      var txt = $(this).text();
      var ary = txt.split("");
      var html = "";
      for(var i=0; i<ary.length; i++){
        html+='<span class="char">'+ary[i]+'</span>';
      }
      $(this).html(html);
    })
  }
  function heroPlay(){
    $(".hero_copy").addClass("play");

    clearTimeout(heroTo);
    heroTo=setTimeout(heroIn,4000);
  }
  function heroIn(){
    $(".hero_cover").fadeOut(1000, function(){$(".hero_copy").html(heroCopyTmpl).removeClass("play");});
    videos.hero_movie.play();
    clearTimeout(heroTo);
    heroTo=setTimeout(heroFix,2000);
  }
  function heroFix(){
    $(".hero_logo").addClass("in");
  }

  /*
  * title
  */
  function titleInit(){
    $(".title-anm").each(function(){
      var txt = $(this).text();
      var ary = txt.split("");
      var html = "";
      for(var i=0; i<ary.length; i++){
        if(ary[i+1]==" "){
          html+='<span class="spacer">'+ary[i]+'</span>';
        }else{
          html+='<span>'+ary[i]+'</span>';
        }
      }
      $(this).html(html);
    });
  }

  /*
  * overlay
  */
  function navOpen(){
    $("body").addClass("navOpen");
    if(!isPC){
      if(lastScroll==0)lastScroll=stagePos.top;
      $(".contents").css({transform:'translate3d(0, -'+lastScroll+'px, 0)'});
      scrollTo(0);
    }
  }
  function navClose(callback){
    callback = callback || function(){};
    $("body").removeClass("navOpen");
    if(!isPC){
      $(".contents").css({transform:'translate3d(0, 0, 0)'});
      setTimeout(function(){
        scrollTo(lastScroll);
        lastScroll=0;
        callback();
      },10);
    }
  }

  /*
  * instagram
  */
  function instaLoad(){
    console.log("load insta");
    loadTargCnt++;
    $.ajax({
        url: "/php/insta.php",//PHPファイルURL
        type:"POST",
        dataType: "json"
    }).done(function(data){
        //通信成功時の処理
        var ary=[];
        $.each(data.data,function(i,item){
            ary.push({
              thumburl: item.images.low_resolution.url,
              imgurl: item.images.standard_resolution.url,
            })
        });

        $(".instaImage").each(function(i){
          if(ary[i]){
            var d=ary[i];
            $(this).find(".image>div").css({"background-image":"url("+d.thumburl+")"});
            $(this).find("a").click(function(_url){
              return function(){
                largeImageOpen(_url,640,640);
                return false;
              }
            }(d.imgurl));
          }
        })

        loadedCnt++;

    }).fail(function(){
        //通信失敗時の処理
    }).always(function(){
        //通信完了時の処理
    });


  }

  function largeImageOpen(url,w,h){
    $(".viewer_largeImage").data({width:w,height:h});
    $(".viewer_largeImage .image>div").css({"background-image":"url("+url+")"});
    $(".viewer").fadeIn(400);
    resizeCheck();
  }


});
