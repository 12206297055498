
//loader
var Loader = {
	_addList:[],
	_imgList:[],
	_totalCnt:0,
	_loadPer:0,
	init:function(init,standby,play){
				this.standbyFunc = standby;
				this.playFunc = play;
				init();
				this._setup();
			},
	imgRegist:function (src){
				this._imgList.push(src);
			},
	_setup:function(){
				this._searchImg();
				this._addImg();
				this._loadStart();
			},
	_searchImg:function (){
				var _=this;
				var addSrcList=[];
				$("body").find("img").each(function(){
					var img=$(this).attr("src");
					if(_._arryCheck(addSrcList,img)){
						addSrcList.push(img);
						_._imgList.push(img);
					}
				});

				var addStyleList=[];
				$("body").find("div,li,a").each(function(){
					var bg=$(this).css("backgroundImage");
					if(bg!="none" && bg.indexOf("(")!=-1){
						var imgSrc=bg.substring(bg.indexOf("(")+1,bg.lastIndexOf(")"));
						imgSrc = imgSrc.replace(/["']/g, '');
						if(_._arryCheck(addStyleList,imgSrc)){
							addStyleList.push(imgSrc);
							_._imgList.push(imgSrc);
						}
					}
				});
			},
	_arryCheck:function (arry,src){
				var ret=true;
				for(var i=0;i<arry.length;i++){
					if(arry[i]==src){
						ret=false;
						break;
					}
				}
				return(ret);
			},
	_addImg:function (){
				for(var i=0;i<this._addList.length;i++){
					this._imgList.push(_addList[i]);
				}
			},
	_loadStart:function (){
				var _=this;
				this._totalCnt=this._imgList.length;
				if(this._totalCnt>0){
					this._loadPer=0;
					for(var i=0;i<this._totalCnt;i++){
						var src=this._imgList[i] || "";
						if(/*src.indexOf("http")==-1 &&*/ src.indexOf(".")>-1){
							$('<img/>')
								.attr('src',src).data({"index":i,"src":src})
								.imagesLoaded(function(instance){
                  var $img=$(instance.elements[0]);
									//var s=$img.data("src");
									_._imgList[$img.data("index")]=null;
								});
						}else{
							this._imgList[i]=null;
						}
					}
					this._loopTimer=setInterval(this._loadLoop.bind(this),30);
				}else{
					this._loadEnd();
				}
			},
	_loadLoop:function (){
				var per=1-(this._imgList.getCount()/this._totalCnt);
        //console.log(this._imgList[0]);
				this._loadPer+=(per - this._loadPer)*0.2;

				if(this._loadPer>0.99){
					this._loadEnd();
				}
			},
	_loadEnd:function (){
				clearInterval(this._loopTimer);
				this.standbyFunc();
				//this._loaderDelete();
				var _=this;
				setTimeout(function(){
					_.playFunc();
				},500);
			}
};
module.exports = Loader;
